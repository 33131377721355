import { Guild } from "../utils/types";

export const guilds: Guild[] = [
  {
    id: "123",
    name: "Developers",
    icon: "",
  },
  {
    id: "124",
    name: "Developers",
    icon: "",
  },
  {
    id: "125",
    name: "Developers",
    icon: "",
  },
  {
    id: "126",
    name: "Developers",
    icon: "",
  },
  {
    id: "127",
    name: "Developers",
    icon: "",
  },
  {
    id: "128",
    name: "Developers",
    icon: "",
  },
  {
    id: "129",
    name: "Developers",
    icon: "",
  },
  {
    id: "130",
    name: "Developers",
    icon: "",
  },
  {
    id: "131",
    name: "Developers",
    icon: "",
  },
  {
    id: "132",
    name: "Developers",
    icon: "",
  },
  {
    id: "133",
    name: "Developers",
    icon: "",
  },
  {
    id: "134",
    name: "Developers",
    icon: "",
  },
  {
    id: "135",
    name: "Developers",
    icon: "",
  },
  {
    id: "136",
    name: "Developers",
    icon: "",
  },
  {
    id: "137",
    name: "Developers",
    icon: "",
  },
  {
    id: "138",
    name: "Developers",
    icon: "",
  },
];
